












































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'elektronik-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    FormSwitch,
  },
  data() {
    return {
      name: 'elektronik-insurance-basic',
      key: 'ELEKTRONIK_INSURANCE',
      versicherungsort: {
        Strabe: '',
        Hausnummer: '',
        PLZ: '',
        Ort: '',
        ZursZone: '',
        Naturgefahrenklasse: '',
      },
      Produktauswahl: {
        ELEKEinzel: true,
        ELEKErganzungstarif: true,
        ELEKPauschal: true,
        Praxispolice: true,
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData'],
  watch: {
    Produktauswahl: {
      handler(value) {
        this.$store.commit('tabsModule/setCustomTabsItems', {
          groupKey: 'insuranceForms',
          elements: this.activeTabs,
        });
      },
      deep: true,
    },
  },
  methods: {
    setValues(insuranceData: any) {
      const calculationData = insuranceData;
      this.versicherungsort = calculationData.versicherungsort;
      this.Produktauswahl = calculationData.Produktauswahl;
    },
    onSubmit() {
      const data = {
        versicherungsort: this.versicherungsort,
        Produktauswahl: this.Produktauswahl,
      };
      this.$emit('basic-data-change', data, this.key, this.nextTabId);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);

    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    nextTabId() {
      const result = 0;
      if (this.Produktauswahl.ELEKEinzel) {
        return 2;
      }
      if (this.Produktauswahl.ELEKErganzungstarif) {
        return 3;
      }
      if (this.Produktauswahl.ELEKPauschal) {
        return 4;
      }
      if (this.Produktauswahl.Praxispolice) {
        return 5;
      }
      return result;
    },
    activeTabs() {
      const result = {
        1: {
          name: 'Grunddaten',
          path: 'elektronik-insurance',
        },
      };
      if (this.Produktauswahl.ELEKEinzel) {
        result['2'] = {
          name: 'ELEK - Einzel',
          path: 'elektronik-insurance-ELEKEinzel',
        };
      }
      if (this.Produktauswahl.ELEKErganzungstarif) {
        result['3'] = {
          name: 'ELEK - Ergänzungstarif',
          path: 'elektronik-insurance-ELEKErganzungstarif',
        };
      }
      if (this.Produktauswahl.ELEKPauschal) {
        result['4'] = {
          name: 'ELEK - Pauschal',
          path: 'elektronik-insurance-ELEKPauschal',
        };
      }
      if (this.Produktauswahl.Praxispolice) {
        result['5'] = {
          name: 'Praxispolice',
          path: 'elektronik-insurance-Praxispolice',
        };
      }
      return result;
    },
  },
});
